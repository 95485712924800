* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-white: #fafafa;
  --color-black: #222;
  --color-red: #f16060;
}

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

body {
  background: $background-color;
  color: $color-white;
  font-family: $font-primary;
  min-height: 100vh;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: $color-white;
}

ol {
  padding-inline-start: 40px;
}

p,
ol {
  margin-top: 1em;
  margin-bottom: 1em;
}

button {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
