.aboutpage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  z-index: 0;
  animation-name: fadeIn;
  animation-duration: 2s;
  // animation-delay: 0.6s;
  animation-fill-mode: forwards;
  @include breakpoint(xs) {
    height: 80vh;
  }
  @include breakpoint(xxs) {
    height: 80vh;
  }
  &__bg {
    position: absolute;
    z-index: 1;
    // background: url("../../../images/bird.png");
    background-size: cover;
    background-position: top;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @include breakpoint(xxs) {
      background-position-x: -508px;
      background-position-y: 68px;
    }
    @include breakpoint(xs) {
      background-position-x: -508px;
      background-position-y: 68px;
    }

    @include breakpoint(sm-up) {
      background-position-x: -660px;
      background-position-y: 31px;
    }

    @include breakpoint(lg) {
      background-position: top;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    @include breakpoint(xxs) {
      padding-top: 10vw;
      padding-bottom: 0;
      margin: 3vh;
      line-height: 40%;
    }
    @include breakpoint(xs) {
      padding-top: 10vw;
      padding-bottom: 0;
      margin: 3vh;
      line-height: 40%;
    }
    h1 {
      margin: 100px 0 0;
      font-size: 8vw;
      line-height: 85%;

      @include breakpoint(xxs) {
        font-size: 20vw;
        text-align: center;
        // line-height: 80%;
        // margin: 10px 0 0 0;
      }
      @include breakpoint(xs) {
        font-size: 20vw;
        text-align: center;
        // line-height: 80%;
        // margin: 10px 0 0 0;
      }
    }
  }

  &__tagline {
    position: relative;
    z-index: 2;
    // top: -14%;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    h2 {
      font-size: 2.5vw;
      // line-height: 10px;
      margin-bottom: 20px;

      @include breakpoint(xxs) {
        font-size: 8vw;
        text-align: center;
      }
      @include breakpoint(xs) {
        font-size: 8vw;
        text-align: center;
      }
    }

    // @include breakpoint(xs) {
    //   bottom: 25%;
    // }
  }

  &__info {
    z-index: 2;
    position: relative;
    // bottom: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    // opacity: 0;
    // animation-name: fadeIn;
    // animation-duration: 2s;
    // animation-delay: 2.5s;
    // animation-fill-mode: forwards;
    margin-top: 10px;
    @include breakpoint(xxs) {
      margin-top: 5px;
      justify-content: space-evenly;
    }
    @include breakpoint(xs) {
      margin-top: 5px;
      justify-content: space-evenly;
    }

    & > :first-child {
      margin-right: 50px;

      @include breakpoint(xxs) {
        margin: 0;
        font-size: 3vw;
      }
      @include breakpoint(xs) {
        margin: 0;
        font-size: 3vw;
      }
    }

    button {
      color: var(--color-white);
      background: none;
      border: 1px solid var(--color-white);
      border-radius: 30px;
      padding: 10px;
      transition: all 0.3s;

      &:hover {
        color: var(--color-black);
        background: var(--color-white);
      }
    }
  }

  &__link {
    &:hover {
      text-decoration: underline;
    }
  }

  &__imprint {
    position: relative;
    // bottom: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
    margin-top: 20px;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    @include breakpoint(xxs) {
      bottom: 2px;
      padding-right: 0;
    }
    @include breakpoint(xs) {
      bottom: 2px;
      padding-right: 0;
    }
  }

  &__logo-img {
    max-width: 100px;
    margin: 0 10px;

    @include breakpoint(xxs) {
      max-width: 70px;
    }
    @include breakpoint(xs) {
      max-width: 70px;
    }
  }
  &__poke-logo-img {
    max-width: 70px;
    margin: 10px 10px;

    @include breakpoint(xxs) {
      max-width: 50px;
    }
    @include breakpoint(xs) {
      max-width: 50px;
    }
  }
  &__video {
    width: 640px;
    height: 360px;
    @include breakpoint(xxs) {
      max-width: 256px;
      height: 320px;
    }
    @include breakpoint(xs) {
      max-width: 300px;
      height: 320px;
    }
  }
}

.kusunda-img {
  width: 80%;
  height: 75%;
  object-fit: scale-down;
  object-position: top;
  @include breakpoint(xxs) {
    width: 90%;
  }
  @include breakpoint(xs) {
    width: 90%;
  }
}
