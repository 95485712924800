.popup {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);

  &.complete {
    position: absolute;
    top: 0;
    background: url("../../../images/soundwaves_leaves_higher.png");
    background-size: cover;
    background-position: top;
  }
}

.popup_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 60%;
  // min-width: 40%;
  max-height: 85%;
  height: auto;
  // background: rgba(41, 58, 71, 0.65);
  background: var(--color-white);
  color: var(--color-black);
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  padding: 30px;
  border-radius: 5px;

  @include breakpoint(xxs) {
    width: 80%;
    height: 80%;
  }
  @include breakpoint(xs) {
    width: 80%;
    height: 80%;
  }

  @include breakpoint(xl) {
    max-width: 730px;
    max-height: 40%;
  }

  button,
  .back-btn {
    border: 1px solid var(--color-black);
    background: none;
    color: var(--color-black);
    padding: 10px;
    border-radius: 30px;
    min-width: 80px;
    margin: 0 0 0 auto;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: var(--color-white);
      background: var(--color-black);
    }
  }
}

.popup_inner {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  // height: 85%;
  width: 100%;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  .about-img {
    width: 100%;
    height: 70%;
    object-fit: cover;
    object-position: top;
    // opacity: 40%;
  }

  .about-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
  }

  .about-logo {
    max-width: 50px;
  }
}
