@mixin breakpoint($class) {
  @if $class == xxs {
    @media (max-width: 360px) {
      @content;
    }
  } @else if $class == xs {
    @media (min-width: 361px) and (max-width: 767px) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: 768px) and (max-width: 1015px) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: 1016px) and (max-width: 1199px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: 2000px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg";
  }
}
