.audio-recorder {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .btn-wrapper {
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__ctrl-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    &:hover .audio-recorder__background {
      opacity: 1;
    }
  }

  &__stop-btn {
    color: var(--color-red) !important;
  }

  .btn-ctrl {
    position: relative;
    z-index: 2;
    color: var(--color-black);
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 50%;
    height: 90px;
    width: 90px;
    font-size: 35px;
    backface-visibility: hidden;
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.6s;

    &:focus {
      outline: none;
    }

    &:hover {
      color: var(--color-black);
      background: var(--color-white);

      i {
        color: var(--color-red);
      }
    }

    &:before,
    &::after {
      content: '';
      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      opacity: 0;
      backface-visibility: hidden;
      animation-duration: 0.4s;
    }

    &::before {
      border: 4px solid var(--color-white);
    }

    &::after {
      border: 2px solid var(--color-white);
    }

    &:hover::before {
      animation-name: ripple-out;
    }

    &:hover::after {
      animation-name: ripple-out;
      animation-delay: 0.1s;
    }
  }

  .play {
    border: 1px solid var(--color-white);
    border-radius: 50%;
    padding: 0;
    font-size: 25px;
    width: 50px;
    height: 50px;
    color: var(--color-white);
    background: none;
    // margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      color: var(--color-black);
      background: var(--color-white);
    }

    &:disabled {
      color: #ffffff82;
      cursor: not-allowed;

      &:hover {
        background: none;
      }
    }

    & > * {
      margin-left: 4px;
      margin-top: 3px;
    }
  }

  .next {
    opacity: 1;
    border: 1px solid var(--color-white);
    border-radius: 30px;
    width: 78px;
    padding: 10px;
    margin-top: 20px;
    font-size: 12px;
    background: none;
    color: var(--color-white);
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      color: var(--color-black);
      background: var(--color-white);
    }

    &:disabled {
      visibility: hidden;
      opacity: 0;
    }
  }

  canvas {
    display: inline-block;
    position: absolute;
    z-index: -1;
    top: -30px;
  }
}
