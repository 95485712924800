.nav {
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;

  @include breakpoint(xxs) {
    justify-content: center;
  }
  @include breakpoint(xs) {
    justify-content: center;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
    user-select: none;
    @include breakpoint(xxs) {
      padding-bottom: 20px;
    }
    @include breakpoint(xs) {
      padding-bottom: 20px;
    }
  }

  &__btns {
    @include breakpoint(xxs) {
      position: absolute;
      width: 95%;
      display: flex;
      justify-content: space-between;
    }
    @include breakpoint(xs) {
      position: absolute;
      width: 95%;
      display: flex;
      justify-content: space-between;
    }
  }

  &__logo-img {
    max-width: 50px;
  }
}
