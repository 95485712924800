.funfact-popup {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  // background: var(--color-white);
  // backdrop-filter: blur(10px) saturate(50%);
  // animation-name: fadeIn;
  // animation-duration: 0.6s;
  // animation-fill-mode: forwards;
}

.funfact-popup_wrapper {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 400px;
  // background: rgba(37, 37, 37, 0.83);
  background: var(--color-white);
  // backdrop-filter: blur(5px);
  color: var(--color-black);
  padding: 30px;
  border-radius: 5px;

  @include breakpoint(xxs) {
    width: 80%;
    height: 380px;
  }
  @include breakpoint(xs) {
    width: 80%;
    height: 380px;
  }

  button {
    border: 1px solid var(--color-black);
    background: none;
    color: var(--color-black);
    padding: 10px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: var(--color-black);
      color: var(--color-white);
    }
  }
}

.funfact-popup_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 85%;
  font-size: 20px;
  // margin-bottom: 10px;
}
