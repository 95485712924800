.waveform-container {
  position: absolute;
  width: 100vw;
  top: -17px;
  z-index: -1;

  & #waveform {
    & wave {
      & canvas {
        width: 100% !important;
      }
    }
  }
}
