.flash-card {
  background: #f7f7f7;
  color: #111;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 15px 4px #000000b0;
  // min-width: 40%;

  @include breakpoint(xxs) {
    width: 90%;
    padding: 30px;
    margin: 20px auto;
  }
  @include breakpoint(xs) {
    width: 90%;
    padding: 30px;
    margin: 20px auto;
  }

  @include breakpoint(xl) {
    min-width: 30%;
  }

  &__top-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .read-instructions {
    cursor: pointer;
  }

  .curr-language {
    font-family: "Libre Baskerville", serif;
    font-style: italic;
    text-align: right;
    user-select: none;

    @include breakpoint(xxs) {
      font-size: 12px;
    }
    @include breakpoint(xs) {
      font-size: 12px;
    }
  }

  .current-word {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .word-display {
    margin: 5% 0;
    transition: font-size 0.6s;
    font-family: "Libre Baskerville", serif;

    @include breakpoint(xxs) {
      font-size: 40px;
    }
    @include breakpoint(xs) {
      font-size: 40px;
    }
    @include breakpoint(sm) {
      font-size: 50px;
    }
    @include breakpoint(md) {
      font-size: 60px;
    }
    @include breakpoint(lg) {
      font-size: 80px;
    }
  }

  .blurred-bg {
    position: absolute;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    // backdrop-filter: blur(3px);

    &.hidden {
      display: none;
    }
  }

  .word-play {
    position: relative;
    z-index: 4;
    background: #f7f7f7;
    width: 90px;
    height: 90px;
    border: 0;
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.2s;

    &:active {
      transform: translateY(1px) scale(0.99);
    }

    &.Sirayan {
      box-shadow: 0px 0px 10px 0px #bf488f80;
      &:hover {
        box-shadow: 0px 0px 12px 3px #bf488f80;
      }
    }

    &.Kusunda {
      box-shadow: 0px 0px 10px 0px #79bd9291;
      &:hover {
        box-shadow: 0px 0px 12px 3px #79bd9291;
      }
    }

    @include breakpoint(xxs) {
      font-size: 40px;
      margin-bottom: 0;
    }
    @include breakpoint(xs) {
      font-size: 40px;
      margin-bottom: 0;
    }
    @include breakpoint(sm) {
      font-size: 40px;
    }

    &:before,
    &::after {
      content: "";
      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      opacity: 0;
      backface-visibility: hidden;
      animation-duration: 0.4s;
    }

    &::before {
      border: 4px solid #fff;
    }

    &::after {
      border: 2px solid #fff;
    }

    &:hover::before {
      animation-name: ripple-out;
    }

    &:hover::after {
      animation-name: ripple-out;
      animation-delay: 0.1s;
    }
  }

  .shimmer {
    color: rgba(0, 0, 0, 0.1);
  }

  .shimmer {
    /* the shimmer magic */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#000),
      to(#000),
      color-stop(0.5, #fff)
    );
    background: -moz-gradient(
      linear,
      left top,
      right top,
      from(#000),
      to(#000),
      color-stop(0.5, #fff)
    );
    background: gradient(
      linear,
      left top,
      right top,
      from(#000),
      to(#000),
      color-stop(0.5, #fff)
    );
    -webkit-background-size: 500px 100%;
    -moz-background-size: 500px 100%;
    background-size: 500px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    -webkit-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 5s;
    -moz-animation-duration: 5s;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #000;
  }

  .divider {
    height: 1px;
    width: 100%;
    background: $color-divider;
    margin: 30px 0;

    @include breakpoint(xxs) {
      margin: 10px 0;
    }
    @include breakpoint(xs) {
      margin: 10px 0;
    }
  }

  .completed {
    display: flex;
    justify-content: center;

    & :not(:last-child) {
      margin-right: 40px;
    }
  }

  .dot {
    width: 10px;
    height: 10px;
    background: $color-dot;
    border-radius: 50%;
  }

  .dot-completed {
    width: 10px;
    height: 10px;
    background: $color-green;
    border-radius: 50%;
  }

  .dot-recording {
    width: 10px;
    height: 10px;
    background: $color-red;
    border-radius: 50%;
  }

  .info-bar {
    display: flex;
    justify-content: space-between;

    & > * {
      user-select: none;
    }

    @include breakpoint(xxs) {
      font-size: 12px;
    }
    @include breakpoint(xs) {
      font-size: 12px;
    }
  }

  .words-left {
    text-align: right;
  }
}
