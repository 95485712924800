@font-face {
  font-family: 'Apercu Pro Light';
  src: url('../../fonts/Apercu-Pro-Light.otf');
}

@font-face {
  font-family: 'Apercu Pro Regular';
  src: url('../../fonts/Apercu-Pro-Regular.otf');
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&display=swap');
