// Colors
$color-black: #111;
$color-white: #dce0df;
$color-green: #88f9d4;
$color-red: #f75c5c;

$color-dot: #adadad;
$color-divider: #d6d6d6;

$background-color: #000;

// Fonts
$font-primary: 'Apercu Pro Light';
