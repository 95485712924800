.instructions {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../../images/soundwaves_leaves_higher.png");
  background-size: cover;
  background-position: top;

  &__container {
    // background: rgba(41, 58, 71, 0.45);
    background: var(--color-white);
    color: var(--color-black);
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(5px);
    padding: 50px;
    border-radius: 5px;
    width: 50%;

    @include breakpoint(xxs) {
      font-size: 14px;
      padding: 20px;
      width: 80%;
    }
    @include breakpoint(xs) {
      font-size: 14px;
      padding: 20px;
      width: 80%;
    }

    @include breakpoint(xl) {
      width: auto;
    }
  }

  &__btn {
    cursor: pointer;
    color: var(--color-black);
  }
}
