.landingpage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: url("../../../images/soundwaves_leaves.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-position: 0px, 60%;
  z-index: 0;
  animation-name: fadeIn;
  animation-duration: 2s;
  // animation-delay: 0.6s;
  animation-fill-mode: forwards;

  &__bg {
    position: absolute;
    z-index: 1;
    // background: url("../../../images/bird.png");
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-position: top;
    // height: 100vh;
    // width: 100vw;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @include breakpoint(xs) {
      background-position-x: -508px;
      background-position-y: 68px;
    }
    @include breakpoint(xxs) {
      background-position-x: -508px;
      background-position-y: 68px;
    }

    @include breakpoint(sm-up) {
      background-position-x: -660px;
      background-position-y: 31px;
    }

    @include breakpoint(lg) {
      background-position: top;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    h1 {
      // text-transform: uppercase;
      margin: 100px 0 0;
      font-size: 8vw;
      // width: 50%;
      line-height: 85%;

      @include breakpoint(xs) {
        font-size: 8vw;
        text-align: center;
        line-height: 99%;
        // margin: 120px 0 0;
      }
      @include breakpoint(xxs) {
        font-size: 8vw;
        text-align: center;
        line-height: 99%;
        // margin: 120px 0 0;
      }
    }
  }

  &__cardheader {
    padding-top: 7%;
    vertical-align: middle;

    @include breakpoint(xs) {
      font-size: 20px;
      padding-top: 20%;
    }
  }

  &__cardsubheader {
    padding-bottom: 7%;
    vertical-align: middle;

    @include breakpoint(xs) {
      font-size: 20px;
      padding-bottom: 200%;
    }
  }

  &__tagline {
    position: absolute;
    z-index: 2;
    bottom: 58%;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    @include breakpoint(xxs) {
      bottom: 60%;
    }
    h2 {
      font-size: 2.5vw;
      // line-height: 10px;
      margin-bottom: 20px;
      @include breakpoint(xxs) {
        font-size: 6vw;
        text-align: center;
      }

      @include breakpoint(xs) {
        font-size: 6vw;
        text-align: center;
      }
    }

    @include breakpoint(xs) {
      bottom: 66%;
    }
  }

  &__get-involved {
    z-index: 2;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
    font-size: 20px;

    & > * {
      text-transform: uppercase;
    }

    @include breakpoint(xxs) {
      font-size: 10px;
      bottom: 20%;
    }

    @include breakpoint(xs) {
      font-size: 14px;
      bottom: 20%;
    }
    @include breakpoint(sm) {
      bottom: 17%;
    }
  }

  &__info {
    z-index: 2;
    position: absolute;
    height: 20%;
    bottom: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;

    @include breakpoint(xs) {
      margin-top: 0px;
      justify-content: space-evenly;
      bottom: 41%;
    }

    & > :first-child {
      margin-right: 50px;

      @include breakpoint(xs) {
        margin: 0;
      }
    }

    button {
      color: var(--color-white);
      background: none;
      border: 1px solid var(--color-white);
      border-radius: 20px;
      padding: 10px;
      transition: all 0.3s;

      &:hover {
        color: var(--color-black);
        background: var(--color-white);
      }
    }

    a {
      justify-content: center;
      vertical-align: middle;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
      color: var(--color-white);
      background: none;
      border: 1px solid var(--color-white);
      border-radius: 15px;
      padding: 16px;
      transition: all 0.3s;
      // width: 20%;
      width: fit-content;
      height: 120%;
      font-size: 25px;
      background: rgba(255, 255, 255, 0.2);
      &:hover {
        opacity: 0.75;
        color: var(--color-black);
        background: var(--color-white);
      }
      @include breakpoint(xxs) {
        font-size: 9px;
        width: 30%;
        height: 90%;
      }
      @include breakpoint(xs) {
        font-size: 10px;
        width: 33%;
      }
      @include breakpoint(sm-up) {
        font-size: 15px;
        width: 30%;
      }
    }
  }

  &__imprint {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;

    @include breakpoint(xs) {
      padding: 0 20px;
      bottom: 20px;
    }
  }

  &__logo-img {
    max-width: 40px;
    margin: 0 10px;
    @include breakpoint(xxs) {
      max-width: 20px;
    }

    @include breakpoint(xs) {
      max-width: 30px;
    }
  }
}
