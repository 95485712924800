.recording-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 2s;

  &.Kusunda {
    background: #253a33;
  }

  &.Sirayan {
    background: #412d43;
  }

  .randomized-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    mix-blend-mode: overlay;
    transition: background-image 0.6s;

    &.sirayan {
      background-image: url("../../../graphics/siraya/sirayan_people.jpg");
      background-size: cover;
      background-position: center;
    }

    &.hema {
      background-image: url("../../../graphics/kusunda/hema.jpg");
      background-size: cover;
      background-position: center;
    }

    &.gyani {
      background-image: url("../../../graphics/kusunda/gyani.jpg");
      background-size: cover;
      background-position: center;
    }

    &.fern {
      background-image: url("../../../graphics/kusunda/fern.jpg");
      background-size: cover;
      background-position: center;
    }
  }

  .recorder-component {
    // position: absolute;
    z-index: 9999;
    min-width: 40%;

    @include breakpoint(xxs) {
      width: 100%;
    }
    @include breakpoint(xs) {
      width: 100%;
    }

    @include breakpoint(xl) {
      min-width: 25%;
    }
  }

  &__container {
    background: rgba(255, 255, 255, 0.15);
    padding: 50px;
    border-radius: 5px;
  }

  &__btn {
    cursor: pointer;
  }
}
