.progress-bar {
  width: 100%;
  height: 6px;
  // margin-top: 30px;
  margin: 30px 0;
  background: #e8e8e8;
  border-radius: 7px;
  // border: 1px solid black;

  &__progress {
    height: 6px;
    width: 0%;
    border-radius: 7px;
    transition: all 0.6s;

    &.Kusunda {
      background: #46ad6b;
    }

    &.Sirayan {
      background: #bf488f;
    }
  }
}
