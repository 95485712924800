.landing-btn {
  display: block;
  font-weight: bold;
  text-align: center;
  border: 1px solid var(--color-white);
  border-radius: 30px;
  width: 200px;
  padding: 12px;
  transition: all 0.3s;
  // backface-visibility: hidden;
  // background: -webkit-gradient(
  //   linear,
  //   left top,
  //   right top,
  //   from(#00000036),
  //   to(#00000036),
  //   color-stop(0.8, #ffffffab)
  // );
  background-size: 1000px 100%;
  background-position: 0px 0px;
  // animation-name: shimmer;
  // animation-duration: 5s;
  // animation-iteration-count: infinite;

  &:hover {
    opacity: 75%;
    background: $color-white;
    color: $color-black;
  }

  &:active {
    transform: scale(0.98);
  }
}

.btn-main {
  background: none;
  color: $color-white;
  border: 1px solid $color-white;
  border-radius: 30px;
  padding: 10px;
  transition: all 0.2s;
  user-select: none;

  &:hover {
    background: $color-white;
    color: $color-black;
  }

  &.btn-nav {
    font-size: 12px;
    margin-left: 20px;
    width: 115px;
    padding: 8px;

    @include breakpoint(xxs) {
      font-size: 8px;
      width: 75px;
      margin: 0;
      padding: 6px;
    }
    @include breakpoint(xs) {
      font-size: 8px;
      width: 75px;
      margin: 0;
      padding: 6px;
    }
  }

  &.btn-instructions-card {
    font-size: 12px;
    border-color: var(--color-black);
    color: var(--color-black);
    padding: 5px;

    &:hover {
      background: var(--color-black);
      color: var(--color-white);
    }
  }
}
