@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

$ripple-size: 10px;

@keyframes ripple-out {
  0% {
    opacity: 0;
  }
  28% {
    opacity: 1;
  }
  100% {
    top: -($ripple-size + $ripple-size);
    right: -($ripple-size + $ripple-size);
    bottom: -($ripple-size + $ripple-size);
    left: -($ripple-size + $ripple-size);
    opacity: 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: top right;
  }

  30% {
    background-position: top left;
  }
}
